import { lazy, StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./assets/scss/index.scss";
import "./i18n";
import Preloader from "./Oow/components/Preloader";
import reportWebVitals from "./reportWebVitals";

const Oow = lazy(() => import('./Oow/App'));
const LgQr = lazy(() => import('./LgQr'));
const Oba = lazy(() => import('./Oba/App'));
const Com = lazy(() => import('./Com/App'));
const Error = lazy(() => import('./General/App'));
const Lg = lazy(() => import('./Lg'));
const MgmInternal = lazy(() => import('./MgmInternal'));
const MgmExternal = lazy(() => import('./MgmExternal'));
const Optin = lazy(() => import("./Optin/App"));
const SfpToken = lazy(() => import("./SfpToken"));
const StoreLocations = lazy(() => import("./StoreLocations"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <StrictMode>
    <Router>
      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route path="/survey/oow/:id" element={<Oow />} />
          <Route path="/form/qr/:referralCode" element={<LgQr />} />
          <Route path="/:language/QnovationsAcademy" element={<Oba />} />
          <Route path="/:language/QnovationsAcademy/:step" element={<Oba />} />
          <Route path="/form/co-meter" element={<Com />} />
          <Route path="/survey/lg/:id" element={<Lg />} />
          <Route path="/mgm-internal/:formType" element={<MgmInternal />} />
          <Route path="/share-number/:consumerId" element={<MgmExternal />} />
          <Route path="/share-number/:consumerId" element={<MgmExternal />} />
          <Route path="/optin" element={<Optin />} />
          <Route path="/sfp-token" element={<SfpToken />} />
          <Route path="/stores" element={<StoreLocations />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </Router>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
